//  -yzm

//other
let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
      {
        pageFlag: false,
        id: "sycsale",
        title: "日常业务同步生单",
        icon: "el-icon-menu",
        children: [
          {
            pageFlag: true,
            id: "ARAP01",
            title: "出纳流水生成收款单",
            name: "ARAP01",
            icon: "el-icon-document",
            component: "views/jde/ARAP01.vue"
            ,visible:true
          },
          {
            pageFlag: true,
            id: "ARAP02",
            title: "出纳流水生成付款单",
            name: "ARAP02",
            icon: "el-icon-document",
            component: "views/jde/ARAP02.vue"
            ,visible:true
          }
        ],
      }
    ],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld